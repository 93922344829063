$(document).ready(function () {
  /*** Header fixed ***/
  $(window).scroll(function () {
    var scrollPixel = $(window).scrollTop();
    if (scrollPixel < 100) {
      $('header').removeClass('headerFix');
    } else {
      $('header').addClass('headerFix');
    };
  });

  $(function(){
    $('#frmDemo').ebcaptcha();
  });
  var randomNr1 = 0; 
  var randomNr2 = 0;
  var totalNr = 0;   

  randomNr1 = Math.floor(Math.random()*10);
  randomNr2 = Math.floor(Math.random()*10);
  totalNr = randomNr1 + randomNr2; 
  jQuery.fn.ebcaptcha = function(options){
    var input = $('#ebcaptchainput'); 
    var label = $('#ebcaptchatext'); 
    var validMsg = $('#captchaAlert'); 
    var texti = "What is "+randomNr1+" + "+randomNr2;
    $(label).text(texti);    
    $(input).keyup(function(){
      var nr = $("#ebcaptchainput").val();  
      if(nr==totalNr)
     {
        validMsg.hide();				
        $(input).removeClass('no-valid');
        $(input).addClass('valid');
      }
      else{
        validMsg.show();
        $(input).removeClass('valid');
        $(input).addClass('no-valid');        
      }			
    });   
  }; 

  $("#frmDemo").on("submit", function(e){
    e.preventDefault();   
    var name = $("#name").val();
    var email = $("#email").val();
    var msg = $("#message").val();
    var nr = $("#ebcaptchainput").val(); 
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;	    
    if(name == "" || email == "" || msg == "" || nr == "" || nr != totalNr){
      $("#error_message").show().html("All Fields are Required");  
    } else if(email.match(mailformat)){
      $("#error_message").html("").hide(); 
        $.ajax({
          type: "POST",
          url: "https://neoboard.app/app/form.php",
          data: "name="+name+"&email="+email+"&message="+msg,
          success: function(data){ 
            $('#myModal').modal('show');
          }
        });
      }else{
        $("#error_message").show().html("All Fields are Required");
      }
  });

  // Contact Form Validation
  window.addEventListener('load', function(){
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function(form){
      form.addEventListener('submit', function(event){
        if (form.checkValidity() === false){
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
  
});

/*** Mobile Header toggle ***/
// Smooth scroll for the navigation and links with .scrollto classes
$('.main-nav a, .mobile-nav a, .scrollto').on('click', function () {
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    var target = $(this.hash);
    if (target.length) {
      var top_space = 0;

      if ($('#header').length) {
        top_space = $('#header').outerHeight();

        if (!$('#header').hasClass('header-scrolled')) {
          top_space = top_space - 40;
        }
      }

      $('html, body').animate({
        scrollTop: target.offset().top - top_space
      }, 1500, 'easeInOutExpo');

      if ($(this).parents('.main-nav, .mobile-nav').length) {
        $('.main-nav .active, .mobile-nav .active').removeClass('active');
        $(this).closest('li').addClass('active');
      }

      if ($('body').hasClass('mobile-nav-active')) {
        $('body').removeClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
        $('.mobile-nav-overly').fadeOut();
      }
      return false;
    }
  }
});

// Navigation active state on scroll
var nav_sections = $('section');
var main_nav = $('.main-nav, .mobile-nav');
var main_nav_height = $('#header').outerHeight();

$(window).on('scroll', function () {
  var cur_pos = $(this).scrollTop();

  nav_sections.each(function () {
    var top = $(this).offset().top - main_nav_height,
      bottom = top + $(this).outerHeight();

    if (cur_pos >= top && cur_pos <= bottom) {
      main_nav.find('li').removeClass('active');
      main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
    }
  });
});

$(window).on("load", function () {
  //Script For SVG CODE
  $(function () {
    jQuery("img.svg").each(function () {
      var $img = jQuery(this);
      var imgID = $img.attr("id");
      var imgClass = $img.attr("class");
      var imgURL = $img.attr("src");

      jQuery.get(
        imgURL,
        function (data) {
          var $svg = jQuery(data).find("svg");
          if (typeof imgID !== "undefined") {
            $svg = $svg.attr("id", imgID);
          }
          if (typeof imgClass !== "undefined") {
            $svg = $svg.attr("class", imgClass + " replaced-svg");
          }

          $svg = $svg.removeAttr("xmlns:a");

          if (!$svg.attr("viewBox") &&
            $svg.attr("height") &&
            $svg.attr("width")
          ) {
            $svg.attr(
              "viewBox",
              "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
            );
          }
          $img.replaceWith($svg);
        },
        "xml"
      );
    });
  });
});